import React from "react"
import ImageWrapper from "@common/imageWrapper"
import TextImage from "@common/textImage"
import TooltipWrapper from "@common/tooltip/tooltipWrapper"
import { CardProps } from "@common/types"
import { isFunction } from "@common/lib/util"

// TODO - wrap in card. Not Button.
// TODO - why's this differrnt from iconCard. combine both.

export default function FlatCard({
  id,
  cardWidthClasses,
  imageUrl,
  initials,
  header,
  description,
  fallBackImg,
  url,
  wrapLink = false,
  newTab = false,
  onClick,
  showTooltip = true,
}: CardProps) {
  const handleClick = () => isFunction(onClick) && onClick(id)

  function renderFlatCardContent() {
    return (
      <button
        className="w-full border py-2 px-4 rounded-md cursor-pointer hover:bg-muted transition-colors"
        onClick={handleClick}
      >
        <div className="w-full flex justify-start items-center">
          {(imageUrl || initials) && (
            <div>
              {imageUrl ? (
                <div className="w-14 h-14 relative">
                  <ImageWrapper
                    src={imageUrl}
                    fallbackUrl={fallBackImg}
                    blurDataURL="/images/backupImages/img-placeholder.svg"
                    className="object-contain"
                    alt={header ?? ""}
                  />
                </div>
              ) : (
                <TextImage char1={initials[0]} char2={initials[1]} />
              )}
            </div>
          )}

          <div className="p-2  text-left min-w-0">
            <TooltipWrapper content={header} disabled={!showTooltip}>
              <p className="text-gray-800 dark:text-gray-300 font-semibold text-md truncate ">
                {header}
              </p>
            </TooltipWrapper>
            <p className="text-gray-700 dark:text-gray-400 text-sm line-clamp-2 ">
              {description} &nbsp;
            </p>
          </div>
        </div>
      </button>
    )
  }

  return (
    <div key={id} className={cardWidthClasses || "md:w-72 lg:w-80 w-full"}>
      {wrapLink && url ? (
        <a href={url} rel="noreferrer" target={newTab ? "_blank" : "_self"}>
          {renderFlatCardContent()}
        </a>
      ) : (
        renderFlatCardContent()
      )}
    </div>
  )
}
